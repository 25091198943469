// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-section .section-wrapper {
  background: #f7f9fc;
  border-radius: 32px;
  padding: 3.5rem;
}
.integration-toos-image {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .integration-section .section-wrapper {
    padding: 0;
  }
  .integration-section .section-heading {
    padding: 3.5rem 2.5rem;
    margin: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/IntegrationSection/integration.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,sBAAsB;IACtB,SAAS;EACX;AACF","sourcesContent":[".integration-section .section-wrapper {\n  background: #f7f9fc;\n  border-radius: 32px;\n  padding: 3.5rem;\n}\n.integration-toos-image {\n  padding-left: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n@media (max-width: 768px) {\n  .integration-section .section-wrapper {\n    padding: 0;\n  }\n  .integration-section .section-heading {\n    padding: 3.5rem 2.5rem;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
