import React from "react";
import "./AboutSection.css";
import aboutImage from "../../assets/AboutSection/aboutImg.svg";
import purpleCheckMark from "../../assets/AboutSection/purple-checkmark-round.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const AboutSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`about-us-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="one"
    >
      <div class="container">
        <div class="section-wrapper">
          <div class="section-heading">
            <div class="heading-tag">
              <p>About</p>
            </div>
            <h2>About AutonomousOps</h2>
            <p>
              AutonomousOps.ai employs advanced intelligent agents to automate
              operational tasks and enhance process efficiency. Its seamless
              integration with existing systems optimizes productivity and
              supports strategic business objectives.
            </p>
          </div>
          <div class="section-content-wrapper">
            <div class="image-container">
              <img src={aboutImage} alt="About us" />
            </div>
            <div class="features">
              <div class="feature feature-1">
                <div class="feature-heading">
                  <img src={purpleCheckMark} alt="Checkmark" />
                  <h3>Autonomous IT Service Management</h3>
                </div>
                <div class="feature-description">
                  <p>
                    Utilizes AI to automate and optimize IT support and
                    operations, improving efficiency and responsiveness.
                  </p>
                </div>
              </div>
              <div class="feature feature-2">
                <div class="feature-heading">
                  <img src={purpleCheckMark} alt="Checkmark" />
                  <h3>Autonomous SoC</h3>
                </div>
                <div class="feature-description">
                  <p>
                    Leverages intelligent agents to enhance security operations,
                    detect threats, and manage responses more effectively.
                  </p>
                </div>
              </div>
              <div class="feature feature-3">
                <div class="feature-heading">
                  <img src={purpleCheckMark} alt="Checkmark" />
                  <h3>Autonomous Customer Operations</h3>
                </div>
                <div class="feature-description">
                  <p>
                    Applies AI to automate and refine customer service
                    processes, driving improved satisfaction and operational
                    efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
