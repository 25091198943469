import React from 'react';
import './AgentAnalyst.css'; // Import CSS file for styling

const AgentAnalystSection = () => {
  return (
    <section className="agent-analyst-section">
      <div className="content">
        <h4 className="roi">ROI</h4>
        <h2 className="headline">
          Discover How Enterprises Grow with Agent Analyst
        </h2>
        <p className="description">
          Automates tasks, optimizes workflows, and reduces costs while enhancing accuracy
        </p>
        <div className="stats">
          <div className="stat">
            <h3 className="stat-value">70%</h3>
            <p className="stat-label">Reduction</p>
          </div>
          <div className="stat">
            <h3 className="stat-value">90%</h3>
            <p className="stat-label">Timely Resolution</p>
          </div>
          <div className="stat">
            <h3 className="stat-value">45%</h3>
            <p className="stat-label">Efficiency</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgentAnalystSection;
