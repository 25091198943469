// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusted-by {
    text-align: center;
    padding: 40px 20px;
  }
  
  .trusted-by-heading {
    font-size: 1rem; 
    margin-bottom: 20px;
    color: #333; 
  }
  
  .trusted-by-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .trusted-by-logos .logo {
    width: 100px;
    height: auto;
    opacity: 0.7; 
    transition: opacity 0.3s ease;
  }
  
  .trusted-by-logos .logo:hover {
    opacity: 1;
  }
  
  /* Ensure the banner section is visually separated from the trusted-by section */
 .trusted-by {
    margin-top: 60px; 
  }

  @media (max-width: 768px) {
    .trusted-by-heading {
      font-size: 1.5rem;
    }
  
    .trusted-by-logos {
      gap: 10px;
    }
  
    .trusted-by-logos .logo {
      width: 80px;
    }
  }
  
  @media (max-width: 480px) {
    .trusted-by-heading {
      font-size: 1.2rem;
    }
  
    .trusted-by-logos {
      flex-direction: column;
      align-items: center;
    }
  
    .trusted-by-logos .logo {
      width: 60px;
      margin-bottom: 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/PartnerSection/partner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,6BAA6B;EAC/B;;EAEA;IACE,UAAU;EACZ;;EAEA,gFAAgF;CACjF;IACG,gBAAgB;EAClB;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,SAAS;IACX;;IAEA;MACE,WAAW;IACb;EACF;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,sBAAsB;MACtB,mBAAmB;IACrB;;IAEA;MACE,WAAW;MACX,mBAAmB;IACrB;EACF","sourcesContent":[".trusted-by {\n    text-align: center;\n    padding: 40px 20px;\n  }\n  \n  .trusted-by-heading {\n    font-size: 1rem; \n    margin-bottom: 20px;\n    color: #333; \n  }\n  \n  .trusted-by-logos {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 20px;\n  }\n  \n  .trusted-by-logos .logo {\n    width: 100px;\n    height: auto;\n    opacity: 0.7; \n    transition: opacity 0.3s ease;\n  }\n  \n  .trusted-by-logos .logo:hover {\n    opacity: 1;\n  }\n  \n  /* Ensure the banner section is visually separated from the trusted-by section */\n .trusted-by {\n    margin-top: 60px; \n  }\n\n  @media (max-width: 768px) {\n    .trusted-by-heading {\n      font-size: 1.5rem;\n    }\n  \n    .trusted-by-logos {\n      gap: 10px;\n    }\n  \n    .trusted-by-logos .logo {\n      width: 80px;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .trusted-by-heading {\n      font-size: 1.2rem;\n    }\n  \n    .trusted-by-logos {\n      flex-direction: column;\n      align-items: center;\n    }\n  \n    .trusted-by-logos .logo {\n      width: 60px;\n      margin-bottom: 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
