// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/CTASection/CtaBackground.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cta-section {
    position: relative;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    background-size: cover;
    padding: 60px 20px; 
    text-align: center;
    color: #fff; 
}

.cta-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 50px 20px;
    border-radius: 22px;
    margin-bottom: 120px;
}

.cta-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px; 
}

.cta-heading {
    font-size: 3rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 150%;
}

.cta-paragraph {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.5;
    color: #333;
}

.cta-button {
    background: #0D5C9C; 
    border: none;
    color: #fff;
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: inline-block; 
}
`, "",{"version":3,"sources":["webpack://./src/components/ctasection/ctasection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2EAAoF;IACpF,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,gCAAgC;IAChC,qBAAqB;AACzB","sourcesContent":[".cta-section {\n    position: relative;\n    background: url('../../assets/CTASection/CtaBackground.svg') no-repeat center center;\n    background-size: cover;\n    padding: 60px 20px; \n    text-align: center;\n    color: #fff; \n}\n\n.cta-container {\n    max-width: 1400px;\n    margin: 0 auto;\n    padding: 50px 20px;\n    border-radius: 22px;\n    margin-bottom: 120px;\n}\n\n.cta-content {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 40px;\n    border-radius: 8px; \n}\n\n.cta-heading {\n    font-size: 3rem;\n    color: #333;\n    margin-bottom: 30px;\n    line-height: 150%;\n}\n\n.cta-paragraph {\n    font-size: 1.2rem;\n    margin-bottom: 30px;\n    line-height: 1.5;\n    color: #333;\n}\n\n.cta-button {\n    background: #0D5C9C; \n    border: none;\n    color: #fff;\n    padding: 15px 30px;\n    font-size: 1rem;\n    font-weight: bold;\n    border-radius: 8px;\n    cursor: pointer;\n    transition: background 0.3s ease;\n    display: inline-block; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
