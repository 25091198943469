// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-section .section-heading {
  width: 76%;
}
.section-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9rem;
}
.image-container {
  width: 50%;
}
.features {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.feature {
  padding: 2rem 3rem;
  border: 1px solid #e3eeff;
  box-shadow: 4px 4px 7px 0px #6d65ff0a;
  border-radius: 22px;
}
.feature-heading {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.feature-description p {
  font-size: 1.4rem;
  color: #6c6c72;
}
@media (max-width: 1024px) {
  .image-container {
    width: 51%;
  }
  .features {
    width: 47%;
  }
}
@media (max-width: 768px) {
  .section-content-wrapper {
    flex-direction: column;
    gap: 4rem;
  }
  .image-container {
    width: 100%;
  }
  .features {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/aboutsection/AboutSection.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,qCAAqC;EACrC,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".about-us-section .section-heading {\r\n  width: 76%;\r\n}\r\n.section-content-wrapper {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  margin-top: 9rem;\r\n}\r\n.image-container {\r\n  width: 50%;\r\n}\r\n.features {\r\n  width: 50%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 2rem;\r\n}\r\n.feature {\r\n  padding: 2rem 3rem;\r\n  border: 1px solid #e3eeff;\r\n  box-shadow: 4px 4px 7px 0px #6d65ff0a;\r\n  border-radius: 22px;\r\n}\r\n.feature-heading {\r\n  display: flex;\r\n  align-items: flex-start;\r\n  gap: 1rem;\r\n  margin-bottom: 1.5rem;\r\n}\r\n.feature-description p {\r\n  font-size: 1.4rem;\r\n  color: #6c6c72;\r\n}\r\n@media (max-width: 1024px) {\r\n  .image-container {\r\n    width: 51%;\r\n  }\r\n  .features {\r\n    width: 47%;\r\n  }\r\n}\r\n@media (max-width: 768px) {\r\n  .section-content-wrapper {\r\n    flex-direction: column;\r\n    gap: 4rem;\r\n  }\r\n  .image-container {\r\n    width: 100%;\r\n  }\r\n  .features {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
