import React from 'react';
import './footer.css'; // Import the CSS file

const FooterSection = () => {
  return (
    <footer className="footer-section">
      <div className="footer-content">
        <div className="footer-left">
          <h2 className="footer-heading">AutonomousOps AI</h2>
          <p className="footer-description">
            Empower your team with AI-driven insights and automated recommendations across multiple data sources
          </p>
        </div>
        <hr className="footer-line" />
        <div className="footer-menu-wrapper">
          <div className="footer-menu-left">
            <a href="#legal" className="footer-menu-item">Legal</a>
            <a href="#privacy" className="footer-menu-item">Privacy Policy</a>
            <a href="#terms" className="footer-menu-item">Terms & Conditions</a>
          </div>
          <div className="footer-copyright">
            <p>Copyright 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};


export default FooterSection;
