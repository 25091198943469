// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-section {
    text-align: center;
    padding: 20px;
    margin: 0 auto;
  }
  
  .main-heading {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .integration-content {
    max-width: 800px; 
    margin: 0 auto;
  }
  
  .sub-heading {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .description {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .integration-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .main-heading {
      font-size: 1.5rem;
    }
  
    .sub-heading {
      font-size: 1.25rem;
    }
  
    .description {
      font-size: 0.875rem;
    }
  }
  
  @media (max-width: 480px) {
    .main-heading {
      font-size: 1.25rem;
    }
  
    .sub-heading {
      font-size: 1rem;
    }
  
    .description {
      font-size: 0.75rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/IntegrationSection/integration.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,cAAc;IACd,cAAc;EAChB;;EAEA,sBAAsB;EACtB;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,kBAAkB;IACpB;;IAEA;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE;MACE,kBAAkB;IACpB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,kBAAkB;IACpB;EACF","sourcesContent":[".integration-section {\n    text-align: center;\n    padding: 20px;\n    margin: 0 auto;\n  }\n  \n  .main-heading {\n    font-size: 2rem;\n    margin-bottom: 10px;\n  }\n  \n  .integration-content {\n    max-width: 800px; \n    margin: 0 auto;\n  }\n  \n  .sub-heading {\n    font-size: 1.5rem;\n    margin: 10px 0;\n  }\n  \n  .description {\n    font-size: 1rem;\n    margin-bottom: 20px;\n  }\n  \n  .integration-image {\n    max-width: 100%;\n    height: auto;\n    display: block;\n    margin: 0 auto;\n  }\n  \n  /* Responsive Styles */\n  @media (max-width: 768px) {\n    .main-heading {\n      font-size: 1.5rem;\n    }\n  \n    .sub-heading {\n      font-size: 1.25rem;\n    }\n  \n    .description {\n      font-size: 0.875rem;\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .main-heading {\n      font-size: 1.25rem;\n    }\n  \n    .sub-heading {\n      font-size: 1rem;\n    }\n  \n    .description {\n      font-size: 0.75rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
