import React, { useState, useRef, useEffect } from 'react';
import './banner.css';
import bgImage from '../../assets/BannerSection/BannerBG.svg';  // Background image for the entire section
import cardsImage from '../../assets/BannerSection/bannerimg.svg';  // The single image containing investigation, recommendation, and alert cards

const Banner = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      hamburgerRef.current && !hamburgerRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="banner" style={{ backgroundImage: `url(${bgImage})` }}>
      {/* Navigation Bar */}
      <nav className="navbar">
        <div className="logo">AutonomousOps AI</div>

        {/* Hamburger Menu Icon */}
        <div
          className={`hamburger ${isMenuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
          ref={hamburgerRef}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Links */}
        <ul
          className={`nav-links ${isMenuOpen ? 'nav-active' : ''}`}
          ref={menuRef}
        >
          <li><a href="#">About</a></li>
          <li><a href="#">Benefits</a></li>
          <li><a href="#">Use cases</a></li>
          <li><a href="#">FAQ</a></li>
        </ul>
      </nav>

      {/* Banner Content */}
      <div className="banner-content">
        <div className="left-side">
          <h2>Customer Operations,  soc, Information Technology</h2>
          <h1>AutonomousOps AI Seamless Ops Automation</h1>
          <p>Driving Operational Efficiency and Productivity with Agents and teammates</p>
          <div className="buttons">
            <button className="btn-primary">Get Started</button>
            <button className="btn-secondary">Request Demo</button>
          </div>
        </div>

        {/* Right side - single image with multiple cards */}
        <div className="right-side">
          <img src={cardsImage} alt="Investigation, Alerts, and Recommendation" className="cards-image" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
