import React from "react";
import "./UseCases.css";
import marketing from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import itService from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import security from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import customer from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import itOperations from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import finance from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import supplyChain from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import hr from '../../assets/UseCasesSection/MarketingGroupsImg.svg';
import threat from '../../assets/UseCasesSection/MarketingGroupsImg.svg';

const UseCases = () => {
    const useCases = [
      { title: "Marketing Operations", image: marketing },
      { title: "IT Service Management", image: itService },
      { title: "Security Operations Center", image: security },
      { title: "Customer Operations", image: customer, description: "Cuts operational costs by reducing staffing needs and optimizing resource use." },
      { title: "IT Operations", image: itOperations },
      { title: "Finance Operations", image: finance },
      { title: "Supply Chain Management", image: supplyChain },
      { title: "HR Management", image: hr },
      { title: "Threat Detection", image: threat },
    ];
  
    return (
      <div className="use-cases-container">
        <h2>Discover How Enterprises Grow with Agent Analyst</h2>
        <p>Learn how teams are using Agent Analyst to create value</p>
        <div className="grid-container">
          {useCases.map((useCase, index) => (
            <div
              key={index}
              className="grid-item"
              style={{ backgroundImage: `url(${useCase.image})` }}
            >
              <div className="overlay">
                <h3>{useCase.title}</h3>
                {useCase.description && <p>{useCase.description}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default UseCases;
