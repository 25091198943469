import React from "react";
import "./secondbanner.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
export default function SecondBanner() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`second-banner-section ${
        isVisible ? "section-animation" : ""
      }`}
      ref={ref}
    >
      <div class="container">
        <div class="second-banner-section-wrapper">
          <div class="second-banner-section-heading">
            <h2>
              Achieve Cohesive Synchronization Through Streamlined Integration
            </h2>
            <p>
              Effortlessly align your operations and technology with
              AutonomousOps.ai, ensuring smooth integration across systems and
              processes
            </p>
            <div
              class="second-banner-button"
              onClick={() => (window.location.href = config.bookdemo.Link)}
            >
              <p>Book Demo</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
