import React from 'react';
import './partner.css'; 
import googleLogo from '../../assets/PartnerSection/google-logo-2.svg';
import intelLogo from '../../assets/PartnerSection/intel-logo-1.svg';
import chubLogo from '../../assets/PartnerSection/chubb-logo-1.svg';
import ibmLogo from '../../assets/PartnerSection/ibm-logo-1.svg';
import skLogo from '../../assets/PartnerSection/SK.svg';
import mellonLogo from '../../assets/PartnerSection/bny-mellon-logo.svg';
import linkedinLogo from '../../assets/PartnerSection/linkedin-logo-1.svg';
import uberLogo from '../../assets/PartnerSection/uber-logo-1.svg'; 

const PartnerSection = () => {
    return(
        <section className="trusted-by">
        <h4 className="trusted-by-heading">Trusted By 25+ Companies</h4>
        <div className="trusted-by-logos">
          <img src={googleLogo} alt="Google" className="logo" />
          <img src={intelLogo} alt="Intel" className="logo" />
          <img src={chubLogo} alt="Chub" className="logo" />
          <img src={ibmLogo} alt="IBM" className="logo" />
          <img src={skLogo} alt="SK" className="logo" />
          <img src={mellonLogo} alt="Mellon" className="logo" />
          <img src={linkedinLogo} alt="LinkedIn" className="logo" />
          <img src={uberLogo} alt="Uber" className="logo" />
        </div>
        </section>
    )
  
}
export default PartnerSection;

