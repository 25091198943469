import React from 'react';
import './AboutSection.css'; 
import aboutImage from '../../assets/AboutSection/aboutImg.svg';

const AboutSection = () => {
  return (
    <section className="about-section" id="about">
      <div className="container">
        <div className="heading">
          <h2>About <span className="highlight">AutonomousOps</span></h2>
          <p>
            AutonomousOps.ai employs advanced intelligent agents to automate operational tasks and enhance
            process efficiency. Its seamless integration with existing systems optimizes productivity and
            supports strategic business objectives.
          </p>
        </div>
        <div className="content">
          <div className="illustration">
            <img src={aboutImage} alt="About AutonomousOps" />
          </div>
          <div className="features">
            <div className="feature-card">
              <h3>✅ IT Operations</h3>
              <p>
                Utilizes AI to automate and optimize IT support and operations, improving efficiency and
                responsiveness.
              </p>
            </div>
            <div className="feature-card">
              <h3>✅ Security Operations</h3>
              <p>
                Utilizes AI to automate and optimize IT support and operations, improving efficiency and
                responsiveness.
              </p>
            </div>
            <div className="feature-card">
              <h3>✅ Software Development</h3>
              <p>
                Utilizes AI to automate and optimize IT support and operations, improving efficiency and
                responsiveness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
