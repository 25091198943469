import React from 'react';
import './BenefitsSection.css'; 
import benefitImage1 from '../../assets/BenefitsSection/benefit-img1.svg';
import benefitImage2 from '../../assets/BenefitsSection/benefit-img2.svg'; 
import benefitImage3 from '../../assets/BenefitsSection/benefit-img3.svg'; 

const BenefitsSection = () => {
  return (
    <section className="benefits-section" id="benefits">
      <div className="container">
        <h2 className="benefits-title">Benefits</h2>
        <h3 className="benefits-subtitle">Streamline Operations Boost Efficiency, Accuracy, and Scalability</h3>
        <p className="benefits-description">Automates tasks, optimizes workflows, and reduces costs while enhancing accuracy</p>

        <div className="benefits-grid">
          {/* First Card - Larger */}
          <div className="benefit-card large-card">
            <img src={benefitImage1} alt="Enhanced Operational Efficiency" />
            <h4>Enhanced Operational Efficiency</h4>
            <p>Automates tasks, optimizes workflows, boosts productivity, and frees employees.</p>
          </div>

          {/* Second Card - Smaller */}
          <div className="benefit-card small-card icon-card">
            <img src={benefitImage2} alt="Improved Accuracy and Consistency" className="icon-image" />
            <h4>Improved Accuracy And Consistency</h4>
            <p>Standardizing processes reduces human error, ensuring consistent and reliable performance.</p>
          </div>

          {/* Third Card - Smaller */}
          <div className="benefit-card small-card icon-card">
            <img src={benefitImage3} alt="Cost Reduction" className="icon-image" />
            <h4>Cost Reduction</h4>
            <p>Cuts operational costs by reducing staffing needs and optimizing resource use.</p>
          </div>

          {/* Fourth Card - Larger with Background */}
          <div className="benefit-card large-card background-card">
            <h4>Increased Agility And Scalability</h4>
            <p>Facilitates rapid adaptation to changing market conditions and scalable growth.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
