import React from "react";
import "./UseCases.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

const UseCases = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`use-cases-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="three"
    >
      <div class="container">
        <div class="section-wrapper">
          <div class="section-heading">
            <div class="heading-tag">
              <p>Use-cases</p>
            </div>
            <h2>Industries Powered by AutonomousOps AI </h2>
            <p>
              Revolutionizing diverse sectors with AI-driven operational
              efficiency.
            </p>
          </div>
          <div class="use-cases-cards-wrapper row">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner first-use-case">
                <div class="use-case-text">
                  <h3>IT Service Management</h3>
                  <p>
                    Automates IT processes to reduce downtime and improve
                    service delivery.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner second-use-case">
                <div class="use-case-text">
                  <h3>Security Operations Center</h3>
                  <p>
                    Enhances threat detection and response through real-time
                    automation.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner third-use-case">
                <div class="use-case-text">
                  <h3>Customer Operations</h3>
                  <p>
                    Streamlines customer support tasks for faster resolutions
                    and higher satisfaction.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner fourth-use-case">
                <div class="use-case-text">
                  <h3>Finance Operations</h3>
                  <p>
                    Automates financial workflows, improving accuracy and
                    compliance.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner fifth-use-case">
                <div class="use-case-text">
                  <h3>Supply Chain Management</h3>
                  <p>
                    Optimizes logistics and resource management through
                    AI-driven automation.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner sixth-use-case">
                <div class="use-case-text">
                  <h3>HR Management</h3>
                  <p>
                    Simplifies recruitment, onboarding, and employee management
                    with intelligent automation.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner seventh-use-case">
                <div class="use-case-text">
                  <h3>Marketing Operations</h3>
                  <p>
                    Drives targeted marketing campaigns and performance tracking
                    through automated processes.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner eighth-use-case">
                <div class="use-case-text">
                  <h3>IT Operations</h3>
                  <p>
                    Streamlines IT operations, enhancing efficiency and incident
                    management.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 use-cases-card">
              <div class="use-case-card-inner ninth-use-case">
                <div class="use-case-text">
                  <h3>Threat Detection</h3>
                  <p>
                    Provides real-time threat monitoring and proactive responses
                    to mitigate security risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
