// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusted-partners {
  margin: 6rem 0 !important;
}
.trusted-by-partners-wrapper {
  text-align: center;
}
.trusted-by-partners-wrapper h2 {
  font-size: 3rem;
  font-weight: 500;
  color: #131313;
}
.partner-logo-images {
  flex-wrap: wrap;
  column-gap: 12rem;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
  row-gap: 2rem;
}
.partner-logo-images img {
  width: 90px;
}

@media (max-width: 768px) {
  .trusted-by-partners-wrapper {
    text-align: left;
  }
  .partner-logo-images {
    column-gap: 7rem;
    display: flex;
    gap: 40px;
  }
  .partner-logo-images img {
    width: 10rem;
  }
}
@media (max-width: 500px) {
  .partner-logo-images {
    justify-content: flex-start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PartnerSection/partner.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,WAAW;AACb;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;IAChB,aAAa;IACb,SAAS;EACX;EACA;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".trusted-partners {\n  margin: 6rem 0 !important;\n}\n.trusted-by-partners-wrapper {\n  text-align: center;\n}\n.trusted-by-partners-wrapper h2 {\n  font-size: 3rem;\n  font-weight: 500;\n  color: #131313;\n}\n.partner-logo-images {\n  flex-wrap: wrap;\n  column-gap: 12rem;\n  justify-content: center;\n  margin-top: 3rem;\n  align-items: center;\n  row-gap: 2rem;\n}\n.partner-logo-images img {\n  width: 90px;\n}\n\n@media (max-width: 768px) {\n  .trusted-by-partners-wrapper {\n    text-align: left;\n  }\n  .partner-logo-images {\n    column-gap: 7rem;\n    display: flex;\n    gap: 40px;\n  }\n  .partner-logo-images img {\n    width: 10rem;\n  }\n}\n@media (max-width: 500px) {\n  .partner-logo-images {\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
