import React from "react";
import "./footer.css"; // Import the CSS file
import autonomousLogoBlue from "../../assets/BannerSection/autonomousops-blue-logo.svg";
const FooterSection = () => {
  return (
    <footer>
      <div class="footer__wrapper">
        <div class="container">
          <div class="footer-inner">
            <div class="footer-items">
              <div class="footer-logo">
                <img src={autonomousLogoBlue} alt="autonomousops-logo" />
              </div>
              <div class="row">
                <div class="list-name">
                  <p>
                    Empower your team with AI-driven insights and automated
                    recommendations across multiple data sources
                  </p>
                </div>
              </div>
            </div>
            <div class="footer-end">
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm-7 col-md-7 col-lg-7">
                  <div class="footer-links">
                    <p>Legal</p>
                    <p>Privacy Policy</p>
                    <p>Terms & Conditions</p>
                  </div>
                </div>
                <div class="col-sm-5 col-md-5 col-lg-5 copyright">
                  <p>Copyright 2024. All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
