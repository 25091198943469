import React from 'react';
import './integration.css';
import integrationImgs from  "../../assets/IntegrationSection/integrateSectionImg.svg"
const IntegrationSection = () => {
  return (
    <section className="integration-section">
      <h2 className="main-heading">Integrate tools</h2>
      <div className="integration-content">
        <h3 className="sub-heading">Integrate with favourite tool</h3>
        <p className="description">Learn how teams are using Agent Analyst to create value</p>
        <img
          className="integration-image"
          src={integrationImgs}
          alt="Teams using Agent Analyst"
        />
      </div>
    </section>
  );
};

export default IntegrationSection;
