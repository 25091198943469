import React from "react";
import "./integration.css";
import integrationImgs from "../../assets/IntegrationSection/integration-tools.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const IntegrationSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`integration-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div class="container">
        <div class="section-wrapper">
          <div class="section-heading">
            <div class="heading-tag">
              <p>Integrate Tools</p>
            </div>
            <h2>Integrate with Your Favourite Tools</h2>
            <p>
              Seamlessly connect AutonomousOps AI with the tools you already use
              to enhance value and efficiency.{" "}
            </p>
          </div>
          <div class="integration-toos-image">
            <img src={integrationImgs} alt="integration-tools" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntegrationSection;
