import React from 'react';
import './App.css';  // Optional: if you have global styles
import AboutSection from './components/aboutsection/AboutSection';
import AgentAnalystSection from './components/AgentAnalystSection/AgentAnalyst';
import Banner from './components/banner/banner';
import BenefitsSection from './components/BenefitsSection/BenefitsSection';
import FAQ from './components/FAQSection/FAQ';
import UseCases from './components/UseCasesSection/UseCases';
import CTASection from './components/ctasection/ctasection';
import IntegrationSection from './components/IntegrationSection/integration';
import FooterSection from './components/FooterSection/footer';
import PartnerSection from './components/PartnerSection/partner';

const App = () => {
  return (
    <div className="App">
      <Banner />
      <PartnerSection/>
     <AboutSection />
     <BenefitsSection/>
     <UseCases />
     <FAQ/>
     <AgentAnalystSection/>
     <CTASection />
     <IntegrationSection />
     <FooterSection/>
    </div>
  );
};

export default App;
